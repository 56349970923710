import { MediaCategory, CancellablePromise } from "@twilio/mcs-client";
import { parseToNumber } from "./util";
import { SendEmailOptions, SendMediaOptions } from "./conversation";
import { JSONValue } from "./types";
import { Messages } from "./data/messages";
import { ContentTemplateVariable } from "./content-template";

/**
 * An unsent message. Returned from {@link MessageBuilder.build}.
 */
class UnsentMessage {
  public text?: string;
  public attributes: JSONValue = {};
  public mediaContent: [MediaCategory, FormData | SendMediaOptions][] = [];
  public emailOptions: SendEmailOptions = {};
  public contentSid?: string;
  public contentVariables?: ContentTemplateVariable[];

  /**
   * @internal
   */
  constructor(private messagesEntity: Messages) {}

  /**
   * Send the prepared message to the conversation.
   * @returns Index of the new message in the conversation.
   */
  send(): CancellablePromise<number | null> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const request = this.messagesEntity.sendV2(this);
      onCancel(() => request.cancel());
      try {
        const response = await request;
        resolve(parseToNumber(response.index));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export { UnsentMessage };
