import {
  custom,
  literal,
  objectSchema,
} from "@twilio/declarative-type-validator";

export const json = custom((value) => [
  ["string", "number", "boolean", "object"].includes(typeof value),
  "a JSON type",
]);

export const optionalJson = custom((value) => [
  ["undefined", "string", "number", "boolean", "object"].includes(typeof value),
  "an optional JSON type",
]);

export const sendMediaOptions = objectSchema("send media options", {
  contentType: [literal(null), "string"],
  filename: ["string", "undefined"],
  media: [
    literal("null"),
    "string",
    ...(typeof Buffer === "function" ? [Buffer] : []),
    ...(typeof Blob === "function" ? [Blob] : []),
  ],
});
