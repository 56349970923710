import {
  ChannelMetadataNotFoundError,
  CommandExecutor,
} from "./command-executor";
import { ChannelMetadataResponse } from "./interfaces/commands/channel-metadata-response";
import QuickLRU from "quick-lru";
import { Configuration } from "./configuration";

type ChannelMetadataClientServices = {
  commandExecutor: CommandExecutor;
};

type CacheEntry = {
  item: ChannelMetadata | null;
};

/**
 * Represents channel metadata.
 */
class ChannelMetadata {
  /**
   * Communication channel type.
   */
  public readonly type: string;

  /**
   * The actual metadata.
   */
  public readonly data: unknown;

  /**
   * @internal
   */
  public constructor(type: string, data: unknown) {
    this.type = type;
    this.data = data;

    Object.freeze(data);
  }
}

class ChannelMetadataClient {
  private readonly _services: ChannelMetadataClientServices;
  private readonly _configuration: Configuration;
  private readonly _cache: QuickLRU<string, CacheEntry>;

  public constructor(
    services: ChannelMetadataClientServices,
    configuration: Configuration
  ) {
    this._services = services;
    this._configuration = configuration;
    this._cache = new QuickLRU({
      maxSize: configuration.channelMetadataCacheCapacity,
    });
  }

  public async getChannelMetadata(
    conversationSid: string,
    messageSid: string
  ): Promise<ChannelMetadata | null> {
    const key = `${conversationSid},${messageSid}`;
    const cachedItem = this._cache.get(key);

    if (cachedItem) {
      return cachedItem.item;
    }

    const url = `${this._configuration.links.conversations}/${conversationSid}/Messages/${messageSid}/ChannelMetadata`;
    let metadataResponse: ChannelMetadataResponse;

    try {
      metadataResponse = await this._services.commandExecutor.fetchResource<
        void,
        ChannelMetadataResponse
      >(url);
    } catch (e) {
      if (e instanceof ChannelMetadataNotFoundError) {
        this._cache.set(key, { item: null });
        return null;
      }

      throw new Error(e);
    }

    const metadata = new ChannelMetadata(
      metadataResponse.type,
      metadataResponse.data
    );
    this._cache.set(key, { item: metadata });
    return metadata;
  }
}

export { ChannelMetadataClient, ChannelMetadata };
