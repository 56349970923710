import {
  ParticipantDescriptor,
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason,
  ParticipantEmailBinding,
} from "../participant";
import { Logger } from "../logger";

import { Conversation } from "../conversation";

import { SyncMap, SyncClient } from "twilio-sync";
import { Users } from "./users";
import { CommandExecutor } from "../command-executor";
import { AddParticipantRequest } from "../interfaces/commands/add-participant";
import { ParticipantResponse } from "../interfaces/commands/participant-response";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import { JSONValue } from "../types";

type ParticipantsEvents = {
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
};

const log = Logger.scope("Participants");

export interface ParticipantsServices {
  syncClient: SyncClient;
  users: Users;
  commandExecutor: CommandExecutor;
}

interface ParticipantsLinks {
  participants: string;
}

export interface ParticipantBindingOptions {
  email?: ParticipantEmailBinding;
}

/**
 * @classdesc Represents the collection of participants for the conversation
 * @fires Participants#participantJoined
 * @fires Participants#participantLeft
 * @fires Participants#participantUpdated
 */
class Participants extends ReplayEventEmitter<ParticipantsEvents> {
  private readonly services: ParticipantsServices;
  private readonly links: ParticipantsLinks;

  rosterEntityPromise: Promise<SyncMap> | null = null;

  public readonly conversation: Conversation;
  public readonly participants: Map<string, Participant>; // passed in from Conversation

  constructor(
    conversation: Conversation,
    participants: Map<string, Participant>,
    links: ParticipantsLinks,
    services: ParticipantsServices
  ) {
    super();
    this.conversation = conversation;
    this.participants = participants;
    this.links = links;
    this.services = services;
  }

  async unsubscribe(): Promise<void> {
    if (this.rosterEntityPromise) {
      const entity = await this.rosterEntityPromise;
      entity.close();
      this.rosterEntityPromise = null;
    }
  }

  subscribe(arg: string | SyncMap) {
    const participantsMapPromise =
      typeof arg === "string"
        ? this.services.syncClient.map({ id: arg, mode: "open_existing" })
        : Promise.resolve(arg);

    return (this.rosterEntityPromise =
      this.rosterEntityPromise ||
      participantsMapPromise
        .then((rosterMap) => {
          rosterMap.on(SyncMap.itemAdded, (args) => {
            log.debug(this.conversation.sid + " itemAdded: " + args.item.key);
            this.upsertParticipant(args.item.key, args.item.data).then(
              (participant) => {
                this.emit(Conversation.participantJoined, participant);
              }
            );
          });

          rosterMap.on(SyncMap.itemRemoved, (args) => {
            log.debug(this.conversation.sid + " itemRemoved: " + args.key);
            const participantSid = args.key;
            if (!this.participants.has(participantSid)) {
              return;
            }
            const leftParticipant = this.participants.get(participantSid);
            this.participants.delete(participantSid);
            if (!leftParticipant) {
              return;
            }
            this.emit(Conversation.participantLeft, leftParticipant);
          });

          rosterMap.on(SyncMap.itemUpdated, (args) => {
            log.debug(this.conversation.sid + " itemUpdated: " + args.item.key);
            this.upsertParticipant(args.item.key, args.item.data).catch((e) =>
              log.error(e)
            );
          });

          const participantsPromises: Promise<Participant>[] = [];
          const rosterMapHandler = (paginator) => {
            paginator.items.forEach((item) => {
              participantsPromises.push(
                this.upsertParticipant(item.key, item.data)
              );
            });
            return paginator.hasNextPage
              ? paginator.nextPage().then(rosterMapHandler)
              : null;
          };

          return rosterMap
            .getItems()
            .then(rosterMapHandler)
            .then(() => Promise.all(participantsPromises))
            .then(() => rosterMap);
        })
        .catch((err) => {
          this.rosterEntityPromise = null;
          if (this.services.syncClient.connectionState != "disconnected") {
            log.error(
              "Failed to get roster object for conversation",
              this.conversation.sid,
              err
            );
          }
          log.debug(
            "ERROR: Failed to get roster object for conversation",
            this.conversation.sid,
            err
          );
          throw err;
        }));
  }

  async upsertParticipantFromResponse(
    data: ParticipantResponse
  ): Promise<Participant> {
    const {
      sid,
      attributes: responseAttributes,
      date_created: dateCreated,
      date_updated: dateUpdated,
      identity: responseIdentity,
      role_sid: roleSid,
      messaging_binding: messagingBinding,
    } = data;

    return await this.upsertParticipant(sid, {
      attributes: responseAttributes,
      dateCreated: new Date(dateCreated),
      dateUpdated: new Date(dateUpdated),
      identity: responseIdentity,
      roleSid,
      lastConsumedMessageIndex: null,
      lastConsumptionTimestamp: null,
      type: messagingBinding?.type ?? "chat",
    });
  }

  async upsertParticipant(
    participantSid: string,
    data: ParticipantDescriptor
  ): Promise<Participant> {
    let participant = this.participants.get(participantSid);
    if (participant) {
      return participant._update(data);
    }

    const links = {
      self: `${this.links.participants}/${participantSid}`,
    };

    participant = new Participant(
      data,
      participantSid,
      this.conversation,
      links,
      this.services
    );
    this.participants.set(participantSid, participant);
    participant.on(Participant.updated, (args: ParticipantUpdatedEventArgs) =>
      this.emit(Conversation.participantUpdated, args)
    );
    return participant;
  }

  /**
   * @returns {Promise<Array<Participant>>} returns list of participants {@see Participant}
   */
  async getParticipants(): Promise<Participant[]> {
    return this.rosterEntityPromise
      ? this.rosterEntityPromise.then(() => {
          const participants: Participant[] = [];
          this.participants.forEach((participant) =>
            participants.push(participant)
          );
          return participants;
        })
      : [];
  }

  /**
   * Get participant by SID from conversation
   * @returns {Promise<Participant>}
   */
  async getParticipantBySid(
    participantSid: string
  ): Promise<Participant | null> {
    return this.rosterEntityPromise
      ? this.rosterEntityPromise.then(() => {
          const participant = this.participants.get(participantSid);
          if (!participant) {
            throw new Error(
              "Participant with SID " + participantSid + " was not found"
            );
          }
          return participant;
        })
      : null;
  }

  /**
   * Get participant by identity from conversation
   * @returns {Promise<Participant>}
   */
  async getParticipantByIdentity(
    identity: string
  ): Promise<Participant | null> {
    let foundParticipant: Participant | null = null;
    return this.rosterEntityPromise
      ? this.rosterEntityPromise.then(() => {
          this.participants.forEach((participant) => {
            if (participant.identity === identity) {
              foundParticipant = participant;
            }
          });
          if (!foundParticipant) {
            throw new Error(
              "Participant with identity " + identity + " was not found"
            );
          }
          return foundParticipant;
        })
      : null;
  }

  /**
   * Add a chat participant to the conversation
   */
  async add(
    identity: string,
    attributes: JSONValue
  ): Promise<ParticipantResponse> {
    return await this.services.commandExecutor.mutateResource<
      AddParticipantRequest,
      ParticipantResponse
    >("post", this.links.participants, {
      identity,
      attributes:
        typeof attributes !== "undefined"
          ? JSON.stringify(attributes)
          : undefined,
    });
  }

  /**
   * Add a non-chat participant to the conversation.
   */
  async addNonChatParticipant(
    proxyAddress: string,
    address: string,
    attributes: JSONValue = {},
    bindingOptions: ParticipantBindingOptions = {}
  ): Promise<ParticipantResponse> {
    return await this.services.commandExecutor.mutateResource<
      AddParticipantRequest,
      ParticipantResponse
    >("post", this.links.participants, {
      attributes:
        typeof attributes !== "undefined"
          ? JSON.stringify(attributes)
          : undefined,
      messaging_binding: {
        address,
        proxy_address: proxyAddress,
        name: bindingOptions?.email?.name,
        level: bindingOptions?.email?.level,
      },
    });
  }

  /**
   * Remove the participant with a given identity from a conversation.
   */
  remove(identity: string): Promise<void> {
    return this.services.commandExecutor.mutateResource(
      "delete",
      `${this.links.participants}/${encodeURIComponent(identity)}`
    );
  }
}

export { Participants };

/**
 * Fired when participant joined conversation
 * @event Participants#participantJoined
 * @type {Participant}
 */

/**
 * Fired when participant left conversation
 * @event Participants#participantLeft
 * @type {Participant}
 */

/**
 * Fired when participant updated
 * @event Participants#participantUpdated
 * @type {Object}
 * @property {Participant} participant - Updated Participant
 * @property {Participant#UpdateReason[]} updateReasons - Array of Participant's updated event reasons
 */
