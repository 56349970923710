import { CommandExecutor } from "./command-executor";
import { Configuration } from "./configuration";
import QuickLRU from "quick-lru";
import {
  MessageRecipient,
  MessageRecipientsResponse,
} from "./interfaces/commands/message-recipients-response";
import { Paginator } from "./interfaces/paginator";
import { UriBuilder } from "./util";
import { ResponseMeta } from "./interfaces/commands/response-meta";
import { RestPaginator } from "./rest-paginator";

type MessageRecipientsClientServices = {
  commandExecutor: CommandExecutor;
};

type MessageRecipientsCacheEntry = {
  item: RecipientDescriptor[];
};

/**
 * Message recipient descriptor.
 */
type RecipientDescriptor =
  | EmailRecipientDescriptor
  | UnknownRecipientDescriptor;

/**
 * Email recipient level.
 */
type EmailRecipientLevel = "to" | "from" | "cc";

/**
 * Email recipient descriptor.
 */
class EmailRecipientDescriptor {
  /**
   * Type of recipient.
   */
  public readonly type = "email";

  /**
   * Sid of the message that this recipient belongs to.
   */
  public readonly messageSid: string;

  /**
   * Email recipient level.
   */
  public readonly level: EmailRecipientLevel;

  /**
   * Name of the recipient.
   */
  public readonly name: string;

  /**
   * Address of the recipient.
   */
  public readonly address: string;

  /**
   * @internal
   */
  public constructor(recipient: MessageRecipient) {
    this.messageSid = recipient.message_sid;
    this.level = recipient.level;
    this.name = recipient.name;
    this.address = recipient.address;
  }
}

/**
 * Unknown recipient descriptor. Used to be able to handle recipient types that
 * are not supported by the current version of the SDK.
 */
class UnknownRecipientDescriptor {
  /**
   * Type of recipient.
   */
  public readonly type: string;

  /**
   * Sid of the message that this recipient belongs to.
   */
  public readonly messageSid: string;

  /**
   * Recipient data as a JSON string.
   */
  public readonly rawData: string;

  /**
   * @internal
   */
  public constructor(recipient: MessageRecipient) {
    this.type = recipient.type;
    this.messageSid = recipient.message_sid;
    this.rawData = JSON.stringify(recipient);
  }
}

class MessageRecipientsClient {
  private readonly _services: MessageRecipientsClientServices;
  private readonly _configuration: Configuration;
  private readonly _cache: QuickLRU<string, MessageRecipientsCacheEntry>;

  public constructor(
    services: MessageRecipientsClientServices,
    configuration: Configuration
  ) {
    this._services = services;
    this._configuration = configuration;
    this._cache = new QuickLRU({
      maxSize: configuration.messageRecipientsCacheCapacity,
    });
  }

  public async getRecipientsFromMessage(
    conversationSid: string,
    messageSid: string
  ): Promise<RecipientDescriptor[]> {
    const key = `${conversationSid},${messageSid}`;
    const cachedItem = this._cache.get(key);

    if (cachedItem) {
      return cachedItem.item;
    }

    const url = new UriBuilder(this._configuration.links.conversations)
      .path(conversationSid)
      .path("MessageRecipients")
      .arg("MessageSid", messageSid)
      .build();
    const recipientsResponse =
      await this._services.commandExecutor.fetchResource<
        void,
        MessageRecipientsResponse
      >(url);
    const recipients: RecipientDescriptor[] =
      recipientsResponse.message_recipients.map((recipient) =>
        this._wrapResponse(recipient)
      );

    if (recipients.length > 0) {
      this._cache.set(key, { item: recipients });
    }

    return recipients;
  }

  public async getRecipientsFromConversation(
    conversationSid: string,
    paginatorOptions?: {
      pageToken?: string;
      pageSize?: number;
    }
  ): Promise<Paginator<RecipientDescriptor>> {
    const url = new UriBuilder(this._configuration.links.conversations)
      .path(conversationSid)
      .path("MessageRecipients")
      .arg("PageToken", paginatorOptions?.pageToken ?? undefined)
      .arg("PageSize", paginatorOptions?.pageSize ?? undefined)
      .build();
    const recipientsResponse =
      await this._services.commandExecutor.fetchResource<
        void,
        { message_recipients: MessageRecipient[] } & ResponseMeta
      >(url);
    const allRecipients = recipientsResponse.message_recipients.map(
      (recipient) => this._wrapResponse(recipient)
    );

    for (const recipient of allRecipients) {
      const key = `${conversationSid},${recipient.messageSid}`;
      const existingMessageRecipients = this._cache.get(key)?.item ?? [];
      this._cache.set(key, { item: [...existingMessageRecipients, recipient] });
    }

    return new RestPaginator<RecipientDescriptor>(
      allRecipients,
      (pageToken, pageSize) =>
        this.getRecipientsFromConversation(conversationSid, {
          pageToken,
          pageSize,
        }),
      recipientsResponse.meta.previous_token,
      recipientsResponse.meta.next_token
    );
  }

  private _wrapResponse(recipient: MessageRecipient): RecipientDescriptor {
    switch (recipient.type) {
      case "email":
        return new EmailRecipientDescriptor(recipient);
      default:
        return new UnknownRecipientDescriptor(recipient);
    }
  }
}

export {
  MessageRecipientsClient,
  EmailRecipientDescriptor,
  UnknownRecipientDescriptor,
  RecipientDescriptor,
  EmailRecipientLevel,
};
