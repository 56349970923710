import { CancellablePromise } from "@twilio/mcs-client";
import { ConversationLimits } from "./interfaces/conversation-limits";
import { SendMediaOptions } from "./conversation";
import { UnsentMessage } from "./unsent-message";
import { JSONValue } from "./types";
import { Messages } from "./data/messages";
import { ContentTemplateVariable } from "./content-template";
import { array, validateTypes } from "@twilio/declarative-type-validator";
import { json, sendMediaOptions } from "./interfaces/rules";

/**
 * Message builder. Allows the message to be built and sent via method chaining.
 *
 * Example:
 *
 * ```ts
 * await testConversation.prepareMessage()
 *   .setBody('Hello!')
 *   .setAttributes({foo: 'bar'})
 *   .addMedia(media1)
 *   .addMedia(media2)
 *   .build()
 *   .send();
 * ```
 */
class MessageBuilder {
  private readonly message: UnsentMessage;
  private emailBodies: Map<string, FormData | SendMediaOptions>;
  private emailHistories: Map<string, FormData | SendMediaOptions>;

  /**
   * @internal
   */
  constructor(
    private readonly limits: ConversationLimits,
    messagesEntity: Messages
  ) {
    this.message = new UnsentMessage(messagesEntity);
    this.emailBodies = new Map<string, FormData | SendMediaOptions>();
    this.emailHistories = new Map<string, FormData | SendMediaOptions>();
  }

  /**
   * Sets the message body.
   * @param text Contents of the body.
   */
  @validateTypes("string")
  setBody(text: string): MessageBuilder {
    this.message.text = text;
    return this;
  }

  /**
   * Sets the message subject.
   * @param subject Contents of the subject.
   */
  @validateTypes("string")
  setSubject(subject: string): MessageBuilder {
    this.message.emailOptions.subject = subject;
    return this;
  }

  /**
   * Sets the message attributes.
   * @param attributes Message attributes.
   */
  @validateTypes(json)
  setAttributes(attributes: JSONValue): MessageBuilder {
    this.message.attributes = attributes;
    return this;
  }

  /**
   * Set the email body with a given content type.
   * @param contentType Format of the body to set (text/plain or text/html).
   * @param body Body payload in the selected format.
   */
  @validateTypes("string", [FormData, sendMediaOptions])
  setEmailBody(
    contentType: string,
    body: FormData | SendMediaOptions
  ): MessageBuilder {
    this.emailBodies.set(contentType, body);
    return this;
  }

  /**
   * Set the email history with a given content type.
   * @param contentType Format of the history to set (text/plain or text/html).
   * @param history History payload in the selected format.
   */
  @validateTypes("string", [FormData, sendMediaOptions])
  setEmailHistory(
    contentType: string,
    history: FormData | SendMediaOptions
  ): MessageBuilder {
    this.emailHistories.set(contentType, history);
    return this;
  }

  /**
   * Adds {@link ContentTemplate} SID for the message alongside optional
   * variables. When no variables provided, the default values will be used.
   *
   * Adding the content SID converts the message to a rich message. In this
   * case, other fields are ignored and the message is sent using the content
   * from the the {@link ContentTemplate}.
   *
   * Use {@link Client.getContentTemplates} to request all available
   * {@link ContentTemplate}s.
   *
   * @param contentSid SID of the {@link ContentTemplate}
   * @param variables Custom variables to resolve the template.
   */
  @validateTypes("string", [
    array("content variables", ContentTemplateVariable),
    "undefined",
  ])
  setContentTemplate(
    contentSid: string,
    contentVariables: ContentTemplateVariable[] = []
  ): MessageBuilder {
    this.message.contentSid = contentSid;
    this.message.contentVariables = contentVariables;
    return this;
  }

  /**
   * Adds media to the message.
   * @param payload Media to add.
   */
  @validateTypes([FormData, sendMediaOptions])
  addMedia(payload: FormData | SendMediaOptions): MessageBuilder {
    if (typeof FormData === "undefined" && payload instanceof FormData) {
      throw new Error("Could not add FormData content whilst not in a browser");
    }
    if (!(payload instanceof FormData)) {
      const mediaOptions = payload as SendMediaOptions;
      if (!mediaOptions.contentType || !mediaOptions.media) {
        throw new Error(
          "Media content in SendMediaOptions must contain non-empty contentType and media"
        );
      }
    }
    this.message.mediaContent.push(["media", payload]);
    return this;
  }

  /**
   * Builds the message, making it ready to be sent.
   */
  build(): UnsentMessage {
    this.emailBodies.forEach((_, key) => {
      if (!this.limits.emailBodiesAllowedContentTypes.includes(key)) {
        throw new Error(`Unsupported email body content type ${key}`);
      }
    });
    this.emailHistories.forEach((_, key) => {
      if (!this.limits.emailHistoriesAllowedContentTypes.includes(key)) {
        throw new Error(`Unsupported email history content type ${key}`);
      }
    });
    if (
      this.emailBodies.size > this.limits.emailBodiesAllowedContentTypes.length
    ) {
      throw new Error(
        `Too many email bodies attached to the message (${this.emailBodies.size} > ${this.limits.emailBodiesAllowedContentTypes.length})`
      );
    }
    if (
      this.emailHistories.size >
      this.limits.emailHistoriesAllowedContentTypes.length
    ) {
      throw new Error(
        `Too many email histories attached to the message (${this.emailHistories.size} > ${this.limits.emailHistoriesAllowedContentTypes.length})`
      );
    }

    if (
      this.message.mediaContent.length > this.limits.mediaAttachmentsCountLimit
    ) {
      throw new Error(
        `Too many media attachments in the message (${this.message.mediaContent.length} > ${this.limits.mediaAttachmentsCountLimit})`
      );
    }

    // @todo we don't know the sizes of the attachments in FormData
    // @todo insertion below makes build() method non-repeatable - probably move to UnsentMessage.send() or even sendV2()?

    this.emailBodies.forEach((body) => {
      this.message.mediaContent.push(["body", body]);
    });

    this.emailHistories.forEach((history) => {
      this.message.mediaContent.push(["history", history]);
    });

    return this.message;
  }

  /**
   * Prepares a message and sends it to the conversation.
   */
  buildAndSend(): CancellablePromise<number | null> {
    return this.build().send();
  }
}

export { MessageBuilder };
