import { customError } from "./errors";
import * as ajaxClient from "./ajax_client";

const AjaxUnauthorizedResponseError = customError("AjaxUnauthorizedResponseError")
const AjaxNonSuccessResponseError= customError("AjaxNonSuccessResponseError")

export const post = async (url, params) =>
  _handleResult(url, await ajaxClient.post(url, { params }))

export const patch = async (url, params) =>
  _handleResult(url, await ajaxClient.patch(url, { params }))

export const get = async (url, params, {signal} = {}) =>
  _handleResult(url, await ajaxClient.get(url, { query: params, signal }))

export const del = async (url, params) => // NOTE: delete is a keyword in javascript
  _handleResult(url, await ajaxClient.del(url))

const _handleResult = (url, response) => {
  switch(response.code) {
    case 200:
    case 201:
    case 422:
      return response.body
    case 401:
      throw(new AjaxUnauthorizedResponseError(`Unauthorized Response\n\nUrl: ${url}`))
    default:
      throw(new AjaxNonSuccessResponseError(`Unsuccessful Response\n\nCode: ${response.code}\n Url: ${url}`))
  }
};
