import { customError } from "./errors";

const AjaxNetworkError = customError("AjaxNetworkError")
const JsonParseError = customError("JsonParseError")

export const fetchWithEnhancedErrors = async (url, requestOptions) => {
  const requestContext = new RequestContext(url, requestOptions)
  const fetchResponse = await callFetch(url, requestOptions, requestContext);
  return await buildResponse(fetchResponse, requestContext)
}

const callFetch = async (url, requestOptions, requestContext)  => {
  try {
    return await fetch(url, requestOptions);
  } catch(e) {
    const error = new AjaxNetworkError(
      `${e.message}\n` +
      `\n` +
      requestContext.toString()
    )
    throw error;
  }
}

const buildResponse = async (fetchResponse, requestContext) => {
  return {
    code: fetchResponse.status,
    body: await parse(fetchResponse, requestContext)
  }
}

const parse = async (fetchResponse, requestContext) => {
  const rawBody = await fetchResponse.text()

  try {
    return await JSON.parse(rawBody)
  } catch (e) {
    throw new JsonParseError(
      `Failed to parse JSON response\n`+
      `\n` +
      `Error: ${e.name}\n` +
      `Message: ${e.message}\n` +
      `Raw body: '${rawBody}'\n` +
      requestContext.toString()
    )
  }
}

class RequestContext {
  constructor(url, requestOptions) {
    this.url = url
    this.requestOptions = requestOptions
  }

  toString() {
    return(
      `Url: ${this.url}\n` +
      `RequestOptions: ${JSON.stringify(this.requestOptions)}`
    )
  }
}
