import {
  McsClient,
  McsMedia,
  McsMediaCategory,
  McsMediaState,
  CancellablePromise,
} from "@twilio/mcs-client";

/**
 * Category of media. Possible values are as follows:
 * * `'media'`
 * * `'body'`
 * * `'history'`
 */
type MediaCategory = McsMediaCategory;

interface MediaServices {
  mcsClient: McsClient;
}

/**
 * Represents a media information for a message in a conversation.
 */
class Media {
  private state: McsMediaState;
  private services: MediaServices;
  private mcsMedia: McsMedia | null = null;

  /**
   * @internal
   */
  constructor(data: McsMediaState | McsMedia, services: MediaServices) {
    this.services = services;

    if (data instanceof McsMedia) {
      this.mcsMedia = data as McsMedia;
    }

    this.state = {
      sid: data.sid,
      category: data.category,
      filename: data.filename ?? null,
      contentType: data.contentType,
      size: data.size,
    };
  }

  /**
   * Server-assigned unique identifier for the media.
   */
  public get sid(): string {
    return this.state.sid;
  }

  /**
   * File name. Null if absent.
   */
  public get filename(): string | null {
    return this.state.filename;
  }

  /**
   * Content type of the media.
   */
  public get contentType(): string {
    return this.state.contentType;
  }

  /**
   * Size of the media in bytes.
   */
  public get size(): number {
    return this.state.size;
  }

  /**
   * Media category, can be one of the {@link MediaCategory} values.
   */
  public get category(): MediaCategory {
    return this.state.category;
  }

  /**
   * Returns the direct content URL for the media.
   *
   * This URL is impermanent, it will expire in several minutes and cannot be cached.
   * If the URL becomes expired, you need to request a new one.
   * Each call to this function produces a new temporary URL.
   */
  public getContentTemporaryUrl(): CancellablePromise<string | null> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const fetchMediaRequest = this.mcsMedia
        ? undefined
        : this._fetchMcsMedia();
      let contentUrlRequest = this.mcsMedia?.getContentUrl();

      onCancel(() => {
        if (fetchMediaRequest) {
          fetchMediaRequest.cancel();
        }
        if (contentUrlRequest) {
          contentUrlRequest.cancel();
        }
      });

      try {
        if (!contentUrlRequest) {
          const mcsMedia = await fetchMediaRequest;
          contentUrlRequest = mcsMedia?.getContentUrl();
        }
        resolve(contentUrlRequest ? await contentUrlRequest : null);
      } catch (e) {
        reject(e);
      }
    });
  }

  private _fetchMcsMedia(): CancellablePromise<McsMedia> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      if (this.services.mcsClient === null) {
        reject(new Error("Media Content Service is unavailable"));
      }

      const request = this.services.mcsClient.get(this.state.sid);
      onCancel(() => request.cancel());

      try {
        this.mcsMedia = await request;
        this.state = this.mcsMedia._state();
        resolve(this.mcsMedia);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * @internal
   */
  _state(): McsMediaState {
    return this.state;
  }
}

export { Media, MediaServices, MediaCategory };
