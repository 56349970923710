/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { Controller } from "@hotwired/stimulus";
import { patch } from "../common/utils/ajax";

export default class CheckboxController extends Controller {
  initialize() {
    return this.url = this.data.get("url");
  }

  toggle() {
    return patch(this.url);
  }
}
