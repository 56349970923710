import { Controller } from "@hotwired/stimulus"
import * as mobiscroll from "@mobiscroll/javascript"
import * as ajax from "../common/utils/ajax"
import { min, max } from "lodash"

export default class ReservationCalendarController extends Controller {
  static targets = ["fromDate", "toDate", "mobiscroll", "durationDisplay"]
  static values = {
    locale: String,
    availabilityUrl: String,
    rangeStartLabel: String,
    rangeStartHelp: String,
    rangeEndLabel: String,
    rangeEndHelp: String,
    display: String,
    unavailableLabel: String,
    maxRange: Number,
    admin: { type: Boolean, default: false }
  }
  static classes = ["loading"]

  connect() {
    this.mobiscroll = mobiscroll.datepicker(this.mobiscrollTarget, {
      // Style
      theme: "material",
      themeVariant: "light",
      controls: ["calendar"],
      select: "range",
      display: this.displayValue,
      showRangeLabels: true,
      rangeStartLabel: this.rangeStartLabelValue,
      rangeStartHelp: this.rangeStartHelpValue,
      rangeEndLabel: this.rangeEndLabelValue,
      rangeEndHelp: this.rangeEndHelpValue,
      showInput: false,
      defaultSelection: [this.fromDateTarget.value, this.toDateTarget.value],
      showWeekNumbers: true,
      inputStyle: 'outline',

      // Localization
      locale: mobiscroll.locale[this.localeValue],

      // Constraints
      min: this.fromDateTarget.min,
      max: this.toDateTarget.max,
      minRange: 2,
      maxRange: this.maxRangeValue,
      returnFormat: 'iso8601',
      rangeEndInvalid: true,

      // Callbacks
      onChange: (event, instance) => {
        const [fromDate, toDate] = event.value || []

        const fromDateChanged = this.fromDateTarget.value != fromDate

        this.fromDateTarget.value = fromDate
        this.toDateTarget.value = toDate
        this.fromDateTarget.dispatchEvent(new Event("change"));
        this.toDateTarget.dispatchEvent(new Event("change"));

        if (this.displayValue == "inline") {
          if (!fromDate) {
            instance.setActiveDate("start")
          } else if (!toDate) {
            instance.setActiveDate("end")
          } else if (!fromDateChanged) {
            instance.setActiveDate("start")
          } else {
            instance.setVal([fromDate])
            instance.setActiveDate("end")
          }
        } else {
          const localizedFromDate = new Date(
            this.fromDateTarget.value
          ).toLocaleDateString(this.localeValue);
          const localizedToDate = new Date(
            this.toDateTarget.value
          ).toLocaleDateString(this.localeValue);

          this.durationDisplayTarget.value = `${localizedFromDate} - ${localizedToDate}`;
        }
      },
      onPageLoading: this.load,
    })
  }

  load = async ({ firstDay, lastDay }, instance) => {
    const calendarFirstDay = mobiscroll.formatDate('YYYY-MM-DD', firstDay)
    const calendarLastDay = mobiscroll.formatDate('YYYY-MM-DD', lastDay)

    const [inputFirstDay, inputLastDay] = instance.getVal() || []

    const from = min([inputFirstDay, calendarFirstDay])
    const to = this.#addOneDayToDateString(max([inputLastDay, calendarLastDay]))

    const response = await this.#requestAvailability({
      from,
      to,
      min_from: this.fromDateTarget.min,
    });

    if (!response) {
      return // request was cancelled
    }

    const unavailable_dates = response.unavailable_dates;
    const colors = unavailable_dates.map((date) => ({
      date: date,
      cellCssClass: "calendar__day--closed",
    }));

    instance.setOptions({ invalid: unavailable_dates, colors });
  }

  #addOneDayToDateString(dateString) {
    let date = new Date(Date.parse(dateString))
    date.setDate(date.getDate() + 1)
    return date.toISOString()
  }

  async #requestAvailability(params) {
    this.abortable?.abort()
    this.abortable = new AbortController()

    try {
      this.#isLoadingDelayed = true
      const response = await ajax.get(this.availabilityUrlValue, params, { signal: this.abortable.signal })
      this.#isLoadingDelayed = false

      return response
    } catch (error) {
      if (error instanceof DOMException && error.name == "AbortError") {
        // no-op
        return null
      } else {
        throw error
      }
    }
  }

  set #isLoading(isLoading) {
    const classes = this.loadingClass.split(" ")

    if (isLoading) {
      this.element.classList.add(...classes)
    } else {
      this.element.classList.remove(...classes)
    }
  }

  set #isLoadingDelayed(isLoading) {
    if (isLoading) {
      this._isLoadingTimeoutID ??= setTimeout(() => {
        this.#isLoading = true
      }, 200)
    } else {
      clearTimeout(this._isLoadingTimeoutID)
      this._isLoadingTimeoutID = null
      this.#isLoading = false
    }
  }
}
