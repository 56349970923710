import {
  ContentDataCallToActionResponse,
  ContentDataCardResponse,
  ContentDataListPickerResponse,
  ContentDataLocationResponse,
  ContentDataMediaResponse,
  ContentDataQuickReplyResponse,
  ContentDataTextResponse,
  ContentTemplateResponse,
} from "./interfaces/commands/content-templates-response";

/**
 * Shows a button that sends back a predefined text. Used in
 * {@link ContentDataQuickReply}.
 */
type ContentDataReply = {
  /**
   * Display value of the action. This is the message that will be sent back
   * when the user taps on the button.
   */
  readonly title: string;

  /**
   * Postback payload. This field is not visible to the end user.
   */
  readonly id?: string;
};

/**
 * Shows a button that redirects recipient to a predefined URL.
 */
type ContentDataActionUrl = {
  /**
   * The type discriminant.
   */
  readonly type: "url";

  /**
   * Display value for the action.
   */
  readonly title: string;

  /**
   * URL to direct to when the recipient taps the button.
   */
  readonly url: string;

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Shows a button that calls a phone number.
 */
type ContentDataActionPhone = {
  /**
   * The type discriminant.
   */
  readonly type: "phone";

  /**
   * Display value for the action.
   */
  readonly title: string;

  /**
   * Phone number to call when the recipient taps the button.
   */
  readonly phone: string;

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Shows a button that sends back a predefined text.
 */
type ContentDataActionReply = {
  /**
   * The type discriminant.
   */
  readonly type: "reply";

  /**
   * Display value for the action. This is the message that will be sent back
   * when the user taps on the button.
   */
  readonly title: string;

  /**
   * Postback payload. This field is not visible to the end user.
   */
  readonly id?: string;

  /**
   * Index for the action.
   */
  readonly index: number;

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Used for unknown action types which aren't present in the current version of
 * the Conversations SDK.
 */
type ContentDataActionOther = {
  /**
   * The type discriminant.
   */
  readonly type: "other";

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * A union of possible actions used in {@link ContentDataCallToAction} and
 * {@link ContentDataCard}.
 */
type ContentDataAction =
  | ContentDataActionUrl
  | ContentDataActionPhone
  | ContentDataActionReply
  | ContentDataActionOther;

/**
 * Represents an item in the {@link ContentDataListPicker}.
 */
type ContentDataListItem = {
  /**
   * Unique item identifier. Not visible to the recipient.
   */
  readonly id: string;

  /**
   * Display value of the item.
   */
  readonly item: string;

  /**
   * Description of the item.
   */
  readonly description?: string;
};

/**
 * Contains only the plain text-based content. Represents the twilio/text
 * content type.
 */
type ContentDataText = {
  /**
   * The type discriminant.
   */
  readonly type: "text";

  /**
   * The text of the message you want to send.
   */
  readonly body: string;

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Used to send file attachments, or to send long texts via MMS in the US and
 * Canada. Represents the twilio/media content type.
 */
type ContentDataMedia = {
  /**
   * The type discriminant.
   */
  readonly type: "media";

  /**
   * The text of the message you want to send.
   */
  readonly body?: string;

  /**
   * URLs of the media you want to send.
   */
  readonly media: string[];

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Contains a location pin and an optional label, which can be used to enhance
 * delivery notifications or connect recipients to physical experiences you
 * offer. Represents the twilio/location content type.
 */
type ContentDataLocation = {
  /**
   * The type discriminant.
   */
  readonly type: "location";

  /**
   * The longitude value of the location pin you want to send.
   */
  readonly longitude: number;

  /**
   * The latitude value of the location pin you want to send.
   */
  readonly latitude: number;

  /**
   * The label to be displayed to the end user alongside the location pin.
   */
  readonly label?: string;

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Let recipients tap, rather than type, to respond to the message. Represents
 * the twilio/quick-reply content type.
 */
type ContentDataQuickReply = {
  /**
   * The type discriminant.
   */
  readonly type: "quickReply";

  /**
   * The text of the message you want to send. This is included as a regular
   * text message.
   */
  readonly body: string;

  /**
   * Up to 3 buttons can be created for quick reply. See
   * {@link ContentDataReply}.
   */
  readonly replies: ContentDataReply[];

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Buttons that let recipients tap to trigger actions such as launching a
 * website or making a phone call. Represents the twilio/call-to-action content
 * type.
 */
type ContentDataCallToAction = {
  /**
   * The type discriminant.
   */
  readonly type: "callToAction";

  /**
   * The text of the message you want to send. This is included as a regular
   * text message.
   */
  readonly body: string;

  /**
   * Buttons that recipients can tap on to act on the message.
   */
  readonly actions: ContentDataAction[];

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Shows a menu of up to 10 options, which offers a simple way for users to make
 * a selection. Represents the twilio/list-picker content type.
 */
type ContentDataListPicker = {
  /**
   * The type discriminant.
   */
  readonly type: "listPicker";

  /**
   * The text of the message you want to send. This is rendered as the body of
   * the message.
   */
  readonly body: string;

  /**
   * Display value of the primary button.
   */
  readonly button: string;

  /**
   * List item objects displayed in the list. See {@link ContentDataListItem}.
   */
  readonly items: ContentDataListItem[];

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Shows a menu of up to 10 options, which offers a simple way for users to make
 * a selection. Represents the twilio/card content type.
 */
type ContentDataCard = {
  /**
   * The type discriminant.
   */
  readonly type: "card";

  /**
   * Title of the card.
   */
  readonly title: string;

  /**
   * Subtitle of the card.
   */
  readonly subtitle?: string;

  /**
   * URLs of the media to send with the message.
   */
  readonly media: string[];

  /**
   * Buttons that the recipients can tap on to act on the message.
   */
  readonly actions: ContentDataAction[];

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * Used for unknown content types which aren't present in the current version of
 * the Conversations SDK.
 */
type ContentDataOther = {
  /**
   * The type discriminant.
   */
  readonly type: "other";

  /**
   * Full data as a stringified JSON. This could be used for future content
   * types and fields which are not yet supported by the newest version of
   * the Conversations SDK, or for using newer types in the older versions of
   * the SDK.
   */
  readonly rawData: string;
};

/**
 * A union of possible data types in rich content templates.
 */
type ContentData =
  | ContentDataText
  | ContentDataMedia
  | ContentDataLocation
  | ContentDataQuickReply
  | ContentDataCallToAction
  | ContentDataListPicker
  | ContentDataCard
  | ContentDataOther;

const collectActions = (
  actions: ContentDataCallToActionResponse["actions"]
): ContentDataAction[] => {
  return actions.map((action) => {
    const rawData = JSON.stringify(action);

    switch (action.type) {
      case "QUICK_REPLY":
        return {
          type: "reply",
          title: action.title,
          id: action.id ?? "",
          index: action.index ?? 0,
          rawData,
        };
      case "PHONE_NUMBER":
        return {
          type: "phone",
          title: action.title,
          phone: action.phone ?? "",
          rawData,
        };
      case "URL":
        return {
          type: "url",
          title: action.title,
          url: action.url ?? "",
          rawData,
        };
      default:
        return {
          type: "other",
          rawData,
        };
    }
  });
};

const parseVariant = (type: string, data: unknown): ContentData => {
  const rawData = JSON.stringify(data);

  switch (type) {
    case "twilio/text": {
      const variant = data as ContentDataTextResponse;
      return {
        type: "text",
        body: variant.body,
        rawData,
      };
    }
    case "twilio/media": {
      const variant = data as ContentDataMediaResponse;
      return {
        type: "media",
        body: variant.body,
        media: variant.media,
        rawData,
      };
    }
    case "twilio/location": {
      const variant = data as ContentDataLocationResponse;
      return {
        type: "location",
        longitude: variant.longitude,
        latitude: variant.latitude,
        label: variant.label,
        rawData,
      };
    }
    case "twilio/quick-reply": {
      const variant = data as ContentDataQuickReplyResponse;
      return {
        type: "quickReply",
        body: variant.body,
        replies: variant.actions,
        rawData,
      };
    }
    case "twilio/call-to-action": {
      const variant = data as ContentDataCallToActionResponse;
      return {
        type: "callToAction",
        body: variant.body,
        actions: collectActions(variant.actions),
        rawData,
      };
    }
    case "twilio/list-picker": {
      const variant = data as ContentDataListPickerResponse;
      return {
        type: "listPicker",
        body: variant.body,
        button: variant.button,
        items: variant.items,
        rawData,
      };
    }
    case "twilio/card": {
      const variant = data as ContentDataCardResponse;
      return {
        type: "card",
        title: variant.title,
        subtitle: variant.subtitle,
        media: variant.media ?? [],
        actions: collectActions(variant.actions ?? []),
        rawData,
      };
    }
    default:
      return {
        type: "other",
        rawData,
      };
  }
};

const collectVariants = (
  variants: ContentTemplateResponse["variants"]
): Map<string, ContentData> => {
  const variantsMap = new Map<string, ContentData>();

  for (const [key, value] of Object.entries(variants)) {
    variantsMap.set(key, parseVariant(key, value));
  }

  return variantsMap;
};

/**
 * Represents a variable for a content template. See
 * {@link ContentTemplate.variables}.
 */
class ContentTemplateVariable {
  public constructor(
    /**
     * Name of the variable.
     */
    public readonly name: string,

    /**
     * Key of the variable
     */
    public readonly value: string
  ) {}

  /**
   * Copies the variable with a new value.
   *
   * @param value The new value for the variable.
   */
  public copyWithValue(value: string) {
    return new ContentTemplateVariable(this.name, value);
  }
}

/**
 * A rich content template.
 *
 * Use {@Link Client.getContentTemplates} to request all the templates available
 * for the current account.
 */
class ContentTemplate {
  /**
   * The server-assigned unique identifier for the template.
   */
  public readonly sid: string;

  /**
   * Friendly name used to describe the content. Not visible to the recipient.
   */
  public readonly friendlyName: string;

  /**
   * Variables used by this template.
   */
  public readonly variables: ContentTemplateVariable[];

  /**
   * Variants of the content. See {@link ContentData}.
   */
  public readonly variants: Map<string, ContentData>;

  /**
   * Date of creation.
   */
  public readonly dateCreated: Date;

  /**
   * Date of the last update.
   */
  public readonly dateUpdated: Date;

  /**
   * @internal
   */
  public constructor(contentTemplateResponse: ContentTemplateResponse) {
    this.sid = contentTemplateResponse.sid;
    this.friendlyName = contentTemplateResponse.friendly_name;
    this.variables = Object.entries(
      JSON.parse(contentTemplateResponse.variables) as Record<string, string>
    ).map(([key, value]) => new ContentTemplateVariable(key, value));
    this.variants = collectVariants(contentTemplateResponse.variants);
    this.dateCreated = new Date(contentTemplateResponse.date_created);
    this.dateUpdated = new Date(contentTemplateResponse.date_updated);
  }
}

export {
  ContentDataActionUrl,
  ContentDataActionPhone,
  ContentDataActionReply,
  ContentDataActionOther,
  ContentDataAction,
  ContentDataText,
  ContentDataMedia,
  ContentDataLocation,
  ContentDataReply,
  ContentDataQuickReply,
  ContentDataCallToAction,
  ContentDataListPicker,
  ContentDataListItem,
  ContentDataCard,
  ContentDataOther,
  ContentData,
  ContentTemplate,
  ContentTemplateVariable,
  parseVariant,
};
