import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name"];

  insertName(event) {
    const button = event.target.closest("button");
    this.nameTarget.setAttribute("name", button.name);
  }
}
