import { TransportResult as Result, Transport } from "twilsock";
import { MutationConflictResponse } from "./interfaces/commands/mutation-conflict";
import { v4 as uuidv4 } from "uuid";
import { AsyncRetrier } from "@twilio/operation-retrier";

export interface CommandExecutorServices {
  transport: Transport;
}

const trimSlashes = (url: string): string => url.replace(/(^\/+|\/+$)/g, "");

const isMutationConflictResponse = (
  response: Result<unknown>
): response is Result<MutationConflictResponse> => response.status.code === 202;

/**
 * Reason for why retrier in `CommandExecutor.fetchResource` resolved. If the
 * retrier resolved with type of `"noMetadata"`, that means the retrier got
 * preemptively stopped, as there is no point in retrying to receive metadata
 * for a message after it was confirmed to not exist. If the retrier resolved
 * with type `"success"`, it means that the retrier resolved with a normal
 * response.
 */
type RetrierResolution<T> =
  | {
      type: "success";
      data: T;
    }
  | {
      type: "noMetadata";
    };

class ChannelMetadataNotFoundError extends Error {
  public constructor(message: string) {
    super(message);
  }
}

class CommandExecutor {
  constructor(
    private _serviceUrl: string,
    private _services: CommandExecutorServices,
    private _productId?: string
  ) {}

  private _preProcessUrl(url: string): string {
    const trimmedUrl = trimSlashes(url);

    if (/^https?:\/\//.test(url)) {
      return trimmedUrl;
    }

    return `${trimSlashes(this._serviceUrl)}/${trimmedUrl}`;
  }

  private async _makeRequest<Request = void, Response = void>(
    method: "get" | "post" | "delete",
    url: string,
    requestBody?: Request,
    headers?: Record<string, string>
  ): Promise<Result<Response>> {
    const preProcessedUrl = this._preProcessUrl(url);
    const finalHeaders = {
      "Content-Type": "application/json; charset=utf-8",
      ...(headers || {}),
    };
    let response: Result<Response>;

    switch (method) {
      case "get":
        let getUrl = preProcessedUrl;

        if (requestBody) {
          getUrl +=
            "?" +
            Object.entries(requestBody)
              .map((entry) => entry.map(encodeURIComponent).join("="))
              .join("&");
        }

        response = await this._services.transport.get(
          getUrl,
          finalHeaders,
          this._productId
        );
        break;
      case "post":
        response = await this._services.transport.post(
          preProcessedUrl,
          finalHeaders,
          JSON.stringify(requestBody),
          this._productId
        );
        break;
      case "delete":
        response = await this._services.transport.delete(
          preProcessedUrl,
          finalHeaders,
          {},
          this._productId
        );
        break;
    }

    if (response.status.code < 200 || response.status.code >= 300) {
      throw new Error(
        `Request responded with a non-success code ${response.status.code}`
      );
    }

    return response;
  }

  public async fetchResource<Request = void, Response = void>(
    url: string,
    requestBody?: Request
  ): Promise<Response> {
    const maxAttemptsCount = 6;
    const retrier = new AsyncRetrier({
      min: 50,
      max: 1600,
      maxAttemptsCount,
    });

    let resolution: RetrierResolution<Response>;

    try {
      resolution = await retrier.run<RetrierResolution<Response>>(async () => {
        try {
          const response = await this._makeRequest<Request, Response>(
            "get",
            url,
            requestBody
          );
          return {
            type: "success",
            data: response.body,
          };
        } catch (e) {
          // If we get the 50530 error, which signifies that the message has no
          // metadata, we can stop the retrier by resolving it with the type
          // `"noMetadata"`.
          if (e?.body?.status === 404 && e?.body?.code === 50530) {
            return {
              type: "noMetadata",
            };
          }

          // Any other error will be rethrown, thus continuing work of the
          // retrier.
          throw e;
        }
      });
    } catch {
      throw new Error(`Fetch resource from "${url}" failed.`);
    }

    if (resolution.type === "noMetadata") {
      throw new ChannelMetadataNotFoundError("No metadata found.");
    }

    return resolution.data;
  }

  public async mutateResource<Request = void, Response = void>(
    method: "post" | "delete",
    url: string,
    requestBody?: Request
  ): Promise<Response> {
    const result = await this._makeRequest<Request, Response>(
      method,
      url,
      requestBody,
      {
        "X-Twilio-Mutation-Id": uuidv4(),
      }
    );

    if (isMutationConflictResponse(result)) {
      return await this.fetchResource<undefined, Response>(
        result.body.resource_url
      );
    }

    return result.body;
  }
}

export { CommandExecutor, ChannelMetadataNotFoundError };
