import { Logger } from "../logger";
import { JSONValue } from "../types";

/**
 * Deep-clone an object. Note that this does not work on object containing
 * functions.
 * @param {object} obj - the object to deep-clone
 * @returns {object}
 */
function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

function parseToNumber(value) {
  if (typeof value !== "undefined" && !isNaN(Number(value))) {
    return Number(value);
  }
  return null;
}

// timeString cannot be typed `string` because in member.ts
// call to parseTime(data.lastReadTimestamp) uses number not a string for timestamp.
function parseTime(timeString): Date | null {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

function parseAttributes(
  rawAttributes,
  warningMessage: string,
  log: Logger
): JSONValue {
  let attributes = {};
  if (rawAttributes) {
    try {
      attributes = JSON.parse(rawAttributes);
    } catch (e) {
      log.warn(warningMessage, e);
    }
  }

  return attributes;
}

/**
 * Construct URI with query parameters
 */
class UriBuilder {
  private base: string;
  private args: string[];
  private paths: string[];

  constructor(base: string) {
    this.base = base.replace(/\/$/, "");
    this.args = [];
    this.paths = [];
  }

  public arg(
    name: string,
    value: string | number | boolean | undefined
  ): UriBuilder {
    if (typeof value !== "undefined") {
      this.args.push(
        encodeURIComponent(name) + "=" + encodeURIComponent(value)
      );
    }
    return this;
  }

  public path(name: string): UriBuilder {
    this.paths.push(encodeURIComponent(name));
    return this;
  }

  public build(): string {
    let result = this.base;
    if (this.paths.length) {
      result += "/" + this.paths.join("/");
    }

    if (this.args.length) {
      result += "?" + this.args.join("&");
    }
    return result;
  }
}

export { deepClone, UriBuilder, parseToNumber, parseTime, parseAttributes };
