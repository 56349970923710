import { CommandExecutor } from "./command-executor";
import { ContentTemplate } from "./content-template";
import { ContentTemplatesResponse } from "./interfaces/commands/content-templates-response";
import { UriBuilder } from "./util";

type ContentClientServices = {
  commandExecutor: CommandExecutor;
};

class ContentClient {
  private _cachedTemplates: Readonly<ContentTemplate[]> | null = null;

  public constructor(
    private readonly _services: ContentClientServices,
    private readonly _pageSize: number = 100,
    private readonly _cacheTtlMs: number = 5_000
  ) {}

  public async getContentTemplates(): Promise<Readonly<ContentTemplate[]>> {
    if (this._cachedTemplates !== null) {
      return this._cachedTemplates;
    }

    let [templatesPage, nextToken] = await this._fetchContentTemplates();
    let templates = templatesPage;

    while (nextToken !== null) {
      [templatesPage, nextToken] = await this._fetchContentTemplates(nextToken);
      templates = [...templates, ...templatesPage];
    }

    this._cachedTemplates = Object.freeze(templates);

    setTimeout(() => {
      this._cachedTemplates = null;
    }, this._cacheTtlMs);

    return templates;
  }

  private async _fetchContentTemplates(
    pageToken?: string
  ): Promise<[ContentTemplate[], string?]> {
    const contentTemplatesUrl = "Client/v2/ContentTemplates";
    const url = new UriBuilder(contentTemplatesUrl);

    url.arg("PageSize", this._pageSize);

    if (pageToken !== undefined) {
      url.arg("PageToken", pageToken);
    }

    const response = await this._services.commandExecutor.fetchResource<
      void,
      ContentTemplatesResponse
    >(url.build());

    return [
      response.templates.map((template) => new ContentTemplate(template)),
      response.meta.next_token,
    ];
  }
}

export { ContentClient };
