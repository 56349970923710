import "isomorphic-form-data";
import { Logger } from "./logger";
import { ParticipantBindingOptions, Participants } from "./data/participants";
import {
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason,
} from "./participant";
import { Messages } from "./data/messages";
import {
  Message,
  MessageUpdatedEventArgs,
  MessageUpdateReason,
} from "./message";
import { UriBuilder, parseToNumber, parseTime } from "./util";
import { Users } from "./data/users";
import { Paginator, PaginatorOptions } from "./interfaces/paginator";
import { ConversationsDataSource } from "./data/conversations";
import { McsClient } from "@twilio/mcs-client";
import { SyncClient, SyncDocument, SyncList, SyncMap } from "twilio-sync";
import { TypingIndicator } from "./services/typing-indicator";
import { Network } from "./services/network";
import {
  validateTypesAsync,
  custom,
  literal,
  nonEmptyString,
  nonNegativeInteger,
  objectSchema,
} from "@twilio/declarative-type-validator";
import { json, optionalJson } from "./interfaces/rules";
import { Configuration } from "./configuration";
import { CommandExecutor } from "./command-executor";
import { AddParticipantRequest } from "./interfaces/commands/add-participant";
import { EditConversationRequest } from "./interfaces/commands/edit-conversation";
import { ConversationResponse } from "./interfaces/commands/conversation-response";
import { ParticipantResponse } from "./interfaces/commands/participant-response";
import { EditNotificationLevelRequest } from "./interfaces/commands/edit-notification-level";
import {
  EditLastReadMessageIndexRequest,
  EditLastReadMessageIndexResponse,
} from "./interfaces/commands/edit-last-read-message-index";
import { ConversationLimits } from "./interfaces/conversation-limits";
import { MessageBuilder } from "./message-builder";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import isEqual from "lodash.isequal";
import { JSONValue } from "./types";
import { ChannelMetadataClient } from "./channel-metadata-client";
import {
  MessageRecipientsClient,
  RecipientDescriptor,
} from "./message-recipients-client";

/**
 * Conversation events.
 */
type ConversationEvents = {
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
  updated: (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => void;
  removed: (conversation: Conversation) => void;
};

/**
 * Reason for the `updated` event emission by a conversation.
 */
type ConversationUpdateReason =
  | "attributes"
  | "createdBy"
  | "dateCreated"
  | "dateUpdated"
  | "friendlyName"
  | "lastReadMessageIndex"
  | "state"
  | "status"
  | "uniqueName"
  | "lastMessage"
  | "notificationLevel"
  | "bindings";

/**
 * Status of the conversation, relative to the client: whether the conversation
 * has been `joined` or the client is `notParticipating` in the conversation.
 */
type ConversationStatus = "notParticipating" | "joined";

/**
 * User's notification level for the conversation. Determines
 * whether the currently logged-in user will receive pushes for events
 * in this conversation. Can be either `muted` or `default`, where
 * `default` defers to the global service push configuration.
 */
type NotificationLevel = "default" | "muted";

/**
 * State of the conversation.
 */
interface ConversationState {
  /**
   * Current state.
   */
  current: "active" | "inactive" | "closed";

  /**
   * Date at which the latest conversation state update happened.
   */
  dateUpdated: Date;
}

/**
 * Event arguments for the `updated` event.
 */
interface ConversationUpdatedEventArgs {
  conversation: Conversation;
  updateReasons: ConversationUpdateReason[];
}

/**
 * Binding for email conversation.
 */
interface ConversationBindings {
  email?: ConversationEmailBinding;
  sms?: ConversationSmsBinding;
}

/**
 * Binding for email conversation.
 */
interface ConversationEmailBinding {
  name?: string;
  projected_address: string;
}

/**
 * Binding for SMS conversation.
 */
interface ConversationSmsBinding {
  address?: string;
}

/**
 * Configuration for attaching a media file to a message.
 * These options can be passed to {@link Conversation.sendMessage} and
 * {@link MessageBuilder.addMedia}.
 */
interface SendMediaOptions {
  /**
   * Content type of media.
   */
  contentType: null | string;

  /**
   * Optional filename.
   */
  filename?: string;

  /**
   * Content to post.
   */
  media: null | string | Buffer | Blob;
}

/**
 * These options can be passed to {@link Conversation.sendMessage}.
 */
interface SendEmailOptions {
  /**
   *  Message subject. Ignored for media messages.
   */
  subject?: string;
}

/**
 * Information about the last message of a conversation.
 */
interface LastMessage {
  /**
   * Message's index.
   */
  index?: number;

  /**
   *  Message's creation date.
   */
  dateCreated?: Date;
}

/**
 * Conversation services.
 */
interface ConversationServices {
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
  channelMetadataClient: ChannelMetadataClient;
  messageRecipientsClient: MessageRecipientsClient;
}

/**
 * Internal (private) state of the conversation.
 */
interface ConversationInternalState {
  uniqueName: string | null;
  status: ConversationStatus;
  attributes: JSONValue;
  createdBy?: string;
  dateCreated: Date | null;
  dateUpdated: Date | null;
  friendlyName: string | null;
  lastReadMessageIndex: number | null;
  lastMessage?: LastMessage;
  notificationLevel?: NotificationLevel;
  state?: ConversationState;
  bindings: ConversationBindings;
}

/**
 * Conversation descriptor.
 */
interface ConversationDescriptor {
  channel: string;
  entityName: string;
  uniqueName: string;
  attributes: JSONValue;
  createdBy?: string;
  friendlyName?: string;
  lastConsumedMessageIndex: number;
  dateCreated: Date | null;
  dateUpdated: Date | null;
  notificationLevel?: NotificationLevel;
  bindings?: ConversationBindings;
}

/**
 * Conversation links.
 */
interface ConversationLinks {
  self: string;
  messages: string;
  participants: string;
}

/**
 * Map of the fields that will be processed with update messages.
 */
const fieldMappings = {
  lastMessage: "lastMessage",
  attributes: "attributes",
  createdBy: "createdBy",
  dateCreated: "dateCreated",
  dateUpdated: "dateUpdated",
  friendlyName: "friendlyName",
  lastConsumedMessageIndex: "lastConsumedMessageIndex",
  notificationLevel: "notificationLevel",
  sid: "sid",
  status: "status",
  uniqueName: "uniqueName",
  state: "state",
  bindings: "bindings",
};

/**
 * A conversation represents communication between multiple Conversations
 * clients.
 */
class Conversation extends ReplayEventEmitter<ConversationEvents> {
  /**
   * Fired when a participant has joined the conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - participant that joined the
   * conversation
   * @event
   */
  public static readonly participantJoined = "participantJoined";

  /**
   * Fired when a participant has left the conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - participant that left the
   * conversation
   * @event
   */
  public static readonly participantLeft = "participantLeft";

  /**
   * Fired when data of a participant has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Participant} `participant` - participant that has received the
   *     update
   *     * {@link ParticipantUpdateReason}[] `updateReasons` - array of reasons
   *     for the update
   * @event
   */
  public static readonly participantUpdated = "participantUpdated";

  /**
   * Fired when a new message has been added to the conversation.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been added
   * @event
   */
  public static readonly messageAdded = "messageAdded";

  /**
   * Fired when message is removed from the conversation's message list.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been removed
   * @event
   */
  public static readonly messageRemoved = "messageRemoved";

  /**
   * Fired when data of a message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Message} `message` - message that has received the update
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for
   *     the update
   * @event
   */
  public static readonly messageUpdated = "messageUpdated";

  /**
   * Fired when a participant has stopped typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant that has stopped
   * typing
   * @event
   */
  public static readonly typingEnded = "typingEnded";

  /**
   * Fired when a participant has started typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant that has started
   * typing
   * @event
   */
  public static readonly typingStarted = "typingStarted";

  /**
   * Fired when the data of the conversation has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Conversation} `conversation` - conversation that has received
   *     the update
   *     * {@link ConversationUpdateReason}[] `updateReasons` - array of reasons
   *     for the update
   * @event
   */
  public static readonly updated = "updated";

  /**
   * Fired when the conversation was destroyed or the currently-logged-in user
   * has left private conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - conversation that has been removed
   * @event
   */
  public static readonly removed = "removed";

  /**
   * Logger instance.
   */
  private static readonly _logger = Logger.scope("Conversation");

  /**
   * Unique system identifier of the conversation.
   */
  public readonly sid: string;

  /**
   * Conversation links for REST requests.
   * @internal
   */
  public readonly _links: ConversationLinks;

  /**
   * Configuration of the client that the conversation belongs to.
   */
  private readonly _configuration: Configuration;

  /**
   * Conversation service objects.
   */
  private readonly _services: ConversationServices;

  /**
   * Internal state of the conversation.
   */
  private readonly _internalState: ConversationInternalState;

  /**
   * Name of the conversation entity document.
   */
  private readonly _entityName: string;

  /**
   * Messages entity.
   */
  private readonly _messagesEntity: Messages;

  /**
   * Sync list containing messages.
   */
  private _messagesList?: SyncList;

  /**
   * Map of participants.
   * @internal
   */
  public readonly _participants: Map<string, Participant>;

  /**
   * Participants entity.
   */
  private readonly _participantsEntity: Participants;

  /**
   * Sync map containing participants.
   */
  private _participantsMap?: SyncMap;

  /**
   * Source of the most recent update.
   */
  private _dataSource!: ConversationsDataSource;

  /**
   * Promise for the conversation entity document.
   */
  private _entityPromise!: Promise<SyncDocument> | null;

  /**
   * Conversation entity document.
   */
  private _entity!: SyncDocument | null;

  /**
   * @param descriptor Conversation descriptor.
   * @param sid Conversation SID.
   * @param links Conversation links for REST requests.
   * @param configuration Client configuration.
   * @param services Conversation services.
   * @internal
   */
  public constructor(
    descriptor: ConversationDescriptor,
    sid: string,
    links: ConversationLinks,
    configuration: Configuration,
    services: ConversationServices
  ) {
    super();

    this.sid = sid;
    this._links = links;
    this._configuration = configuration;
    this._services = services;
    this._entityName = descriptor.channel;
    this._internalState = {
      uniqueName: descriptor.uniqueName || null,
      status: "notParticipating",
      attributes: descriptor.attributes ?? {},
      createdBy: descriptor.createdBy,
      dateCreated: parseTime(descriptor.dateCreated),
      dateUpdated: parseTime(descriptor.dateUpdated),
      friendlyName: descriptor.friendlyName || null,
      lastReadMessageIndex: Number.isInteger(
        descriptor.lastConsumedMessageIndex
      )
        ? descriptor.lastConsumedMessageIndex
        : null,
      bindings: descriptor.bindings ?? {},
    };

    if (descriptor.notificationLevel) {
      this._internalState.notificationLevel = descriptor.notificationLevel;
    }

    const participantsLinks = {
      participants: this._links.participants,
    };

    this._participants = new Map();
    this._participantsEntity = new Participants(
      this,
      this._participants, // state leak
      participantsLinks,
      this._services
    );
    this._participantsEntity.on(Conversation.participantJoined, (participant) =>
      // @todo update participants map here??
      this.emit(Conversation.participantJoined, participant)
    );
    this._participantsEntity.on(Conversation.participantLeft, (participant) =>
      // @todo update participants map here??
      this.emit(Conversation.participantLeft, participant)
    );
    this._participantsEntity.on(
      Conversation.participantUpdated,
      (args: ParticipantUpdatedEventArgs) =>
        // @todo update participants map here??
        this.emit(Conversation.participantUpdated, args)
    );

    this._messagesEntity = new Messages(this, configuration, services);
    this._messagesEntity.on(Conversation.messageAdded, (message) =>
      this._onMessageAdded(message)
    );
    this._messagesEntity.on(
      Conversation.messageUpdated,
      (args: MessageUpdatedEventArgs) =>
        this.emit(Conversation.messageUpdated, args)
    );
    this._messagesEntity.on(Conversation.messageRemoved, (message) =>
      this.emit(Conversation.messageRemoved, message)
    );
  }

  /**
   * Unique name of the conversation.
   */
  public get uniqueName(): string | null {
    return this._internalState.uniqueName;
  }

  /**
   * Status of the conversation.
   */
  public get status(): ConversationStatus {
    return this._internalState.status;
  }

  /**
   * Name of the conversation.
   */
  public get friendlyName(): string | null {
    return this._internalState.friendlyName;
  }

  /**
   * Date this conversation was last updated on.
   */
  public get dateUpdated(): Date | null {
    return this._internalState.dateUpdated;
  }

  /**
   * Date this conversation was created on.
   */
  public get dateCreated(): Date | null {
    return this._internalState.dateCreated;
  }

  /**
   * Identity of the user that created this conversation.
   */
  public get createdBy(): string {
    return this._internalState.createdBy ?? "";
  }

  /**
   * Custom attributes of the conversation.
   */
  public get attributes(): JSONValue {
    return this._internalState.attributes;
  }

  /**
   * Index of the last message the user has read in this conversation.
   */
  public get lastReadMessageIndex(): number | null {
    return this._internalState.lastReadMessageIndex;
  }

  /**
   * Last message sent to this conversation.
   */
  public get lastMessage(): LastMessage | undefined {
    return this._internalState.lastMessage ?? undefined;
  }

  /**
   * User notification level for this conversation.
   */
  public get notificationLevel(): NotificationLevel {
    return this._internalState.notificationLevel ?? "default";
  }

  /**
   * Conversation bindings. An undocumented feature (for now).
   * @internal
   */
  public get bindings(): ConversationBindings {
    return this._internalState.bindings;
  }

  /**
   * Current conversation limits.
   */
  public get limits(): ConversationLimits {
    return this._configuration.limits;
  }

  /**
   * State of the conversation.
   */
  public get state(): ConversationState | undefined {
    return this._internalState.state;
  }

  /**
   * Source of the conversation update.
   * @internal
   */
  public get _statusSource(): ConversationsDataSource {
    return this._dataSource;
  }

  /**
   * Preprocess the update object.
   * @param update The update object received from Sync.
   * @param conversationSid The SID of the conversation in question.
   */
  private static preprocessUpdate(update, conversationSid: string) {
    try {
      if (typeof update.attributes === "string") {
        update.attributes = JSON.parse(update.attributes);
      } else if (update.attributes) {
        JSON.stringify(update.attributes);
      }
    } catch (e) {
      Conversation._logger.warn(
        "Retrieved malformed attributes from the server for conversation: " +
          conversationSid
      );
      update.attributes = {};
    }

    try {
      if (update.dateCreated) {
        update.dateCreated = new Date(update.dateCreated);
      }
    } catch (e) {
      Conversation._logger.warn(
        "Retrieved malformed dateCreated from the server for conversation: " +
          conversationSid
      );
      delete update.dateCreated;
    }

    try {
      if (update.dateUpdated) {
        update.dateUpdated = new Date(update.dateUpdated);
      }
    } catch (e) {
      Conversation._logger.warn(
        "Retrieved malformed dateUpdated from the server for conversation: " +
          conversationSid
      );
      delete update.dateUpdated;
    }

    try {
      if (update.lastMessage && update.lastMessage.timestamp) {
        update.lastMessage.timestamp = new Date(update.lastMessage.timestamp);
      }
    } catch (e) {
      Conversation._logger.warn(
        "Retrieved malformed lastMessage.timestamp from the server for conversation: " +
          conversationSid
      );
      delete update.lastMessage.timestamp;
    }
  }

  /**
   * Add a participant to the conversation by its identity.
   * @param identity Identity of the Client to add.
   * @param attributes Attributes to be attached to the participant.
   * @returns The added participant.
   */
  @validateTypesAsync(nonEmptyString, optionalJson)
  public async add(
    identity: string,
    attributes?: JSONValue
  ): Promise<ParticipantResponse> {
    return this._participantsEntity.add(identity, attributes ?? {});
  }

  /**
   * Add a non-chat participant to the conversation.
   * @param proxyAddress Proxy (Twilio) address of the participant.
   * @param address User address of the participant.
   * @param attributes Attributes to be attached to the participant.
   * @param bindingOptions Options for adding email participants - name and
   * CC/To level.
   * @returns The added participant.
   */
  @validateTypesAsync(
    nonEmptyString,
    nonEmptyString,
    optionalJson,
    optionalJson
  )
  public async addNonChatParticipant(
    proxyAddress: string,
    address: string,
    attributes: JSONValue = {},
    bindingOptions: ParticipantBindingOptions = {}
  ): Promise<ParticipantResponse> {
    return this._participantsEntity.addNonChatParticipant(
      proxyAddress,
      address,
      attributes ?? {},
      bindingOptions ?? {}
    );
  }

  /**
   * Advance the conversation's last read message index to the current read
   * horizon. Rejects if the user is not a participant of the conversation. Last
   * read message index is updated only if the new index value is higher than
   * the previous.
   * @param index Message index to advance to.
   * @return Resulting unread messages count in the conversation.
   */
  @validateTypesAsync(nonNegativeInteger)
  public async advanceLastReadMessageIndex(index: number): Promise<number> {
    await this._subscribeStreams();

    if (index < (this.lastReadMessageIndex ?? 0)) {
      return await this._setLastReadMessageIndex(this.lastReadMessageIndex);
    }

    return await this._setLastReadMessageIndex(index);
  }

  /**
   * Delete the conversation and unsubscribe from its events.
   */
  public async delete(): Promise<Conversation> {
    await this._services.commandExecutor.mutateResource(
      "delete",
      this._links.self
    );

    return this;
  }

  /**
   * Get the custom attributes of this Conversation.
   */
  public async getAttributes(): Promise<JSONValue> {
    await this._subscribe();
    return this.attributes;
  }

  /**
   * Returns messages from the conversation using the paginator interface.
   * @param pageSize Number of messages to return in a single chunk. Default is
   * 30.
   * @param anchor Index of the newest message to fetch. Default is from the
   * end.
   * @param direction Query direction. By default, it queries backwards
   * from newer to older. The `"forward"` value will query in the opposite
   * direction.
   * @return A page of messages.
   */
  @validateTypesAsync(
    ["undefined", nonNegativeInteger],
    ["undefined", nonNegativeInteger],
    ["undefined", literal("backwards", "forward")]
  )
  public async getMessages(
    pageSize?: number,
    anchor?: number,
    direction?: "backwards" | "forward"
  ): Promise<Paginator<Message>> {
    await this._subscribeStreams();
    return this._messagesEntity.getMessages(pageSize, anchor, direction);
  }

  /**
   * Get a list of all the participants who are joined to this conversation.
   */
  public async getParticipants(): Promise<Participant[]> {
    await this._subscribeStreams();
    return this._participantsEntity.getParticipants();
  }

  /**
   * Get conversation participants count.
   *
   * This method is semi-realtime. This means that this data will be eventually
   * correct, but will also be possibly incorrect for a few seconds. The
   * Conversations system does not provide real time events for counter values
   * changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any
   * core application logic based on these counters being accurate in real time.
   */
  public async getParticipantsCount(): Promise<number> {
    const url = new UriBuilder(this._configuration.links.conversations)
      .path(this.sid)
      .build();
    const response = await this._services.network.get<ConversationResponse>(
      url
    );

    return response.body.participants_count ?? 0;
  }

  /**
   * Get a participant by its SID.
   * @param participantSid Participant SID.
   */
  @validateTypesAsync(nonEmptyString)
  public async getParticipantBySid(
    participantSid: string
  ): Promise<Participant | null> {
    return this._participantsEntity.getParticipantBySid(participantSid);
  }

  /**
   * Get a participant by its identity.
   * @param identity Participant identity.
   */
  @validateTypesAsync(nonEmptyString)
  public async getParticipantByIdentity(
    identity: string | null = ""
  ): Promise<Participant | null> {
    return this._participantsEntity.getParticipantByIdentity(identity ?? "");
  }

  /**
   * Get the total message count in the conversation.
   *
   * This method is semi-realtime. This means that this data will be eventually
   * correct, but will also be possibly incorrect for a few seconds. The
   * Conversations system does not provide real time events for counter values
   * changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any
   * core application logic based on these counters being accurate in real time.
   */
  public async getMessagesCount(): Promise<number> {
    const url = new UriBuilder(this._configuration.links.conversations)
      .path(this.sid)
      .build();
    const response = await this._services.network.get<ConversationResponse>(
      url
    );

    return response.body.messages_count ?? 0;
  }

  /**
   * Get count of unread messages for the user if they are a participant of this
   * conversation. Rejects if the user is not a participant of the conversation.
   *
   * Use this method to obtain the number of unread messages together with
   * {@link Conversation.updateLastReadMessageIndex} instead of relying on the
   * message indices which may have gaps. See {@link Message.index} for details.
   *
   * This method is semi-realtime. This means that this data will be eventually
   * correct, but it will also be possibly incorrect for a few seconds. The
   * Conversations system does not provide real time events for counter values
   * changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any
   * core application logic based on these counters being accurate in real time.
   *
   * If the read horizon is not set, this function will return null. This could mean
   * that all messages in the conversation are unread, or that the read horizon system
   * is not being used. How to interpret this `null` value is up to the customer application.
   *
   * @return Number of unread messages based on the current read horizon set for
   * the user or `null` if the read horizon is not set.
   */
  public async getUnreadMessagesCount(): Promise<number | null> {
    const url = new UriBuilder(this._configuration.links.myConversations)
      .path(this.sid)
      .build();
    const response = await this._services.network.get<ConversationResponse>(
      url
    );

    if (response.body.conversation_sid !== this.sid) {
      throw new Error(
        "Conversation was not found in the user conversations list"
      );
    }

    const unreadMessageCount = response.body.unread_messages_count;

    if (typeof unreadMessageCount === "number") {
      return unreadMessageCount;
    }

    return null;
  }

  /**
   * Join the conversation and subscribe to its events.
   */
  public async join(): Promise<Conversation> {
    await this._services.commandExecutor.mutateResource<
      AddParticipantRequest,
      ParticipantResponse
    >("post", this._links.participants, {
      identity: this._configuration.userIdentity,
    });

    return this;
  }

  /**
   * Leave the conversation.
   */
  public async leave(): Promise<Conversation> {
    if (this._internalState.status === "joined") {
      await this._services.commandExecutor.mutateResource(
        "delete",
        `${this._links.participants}/${encodeURIComponent(
          this._configuration.userIdentity
        )}`
      );
    }

    return this;
  }

  /**
   * Remove a participant from the conversation. When a string is passed as the
   * argument, it will assume that the string is an identity or SID.
   * @param participant Identity, SID or the participant object to remove.
   */
  @validateTypesAsync([nonEmptyString, Participant])
  public async removeParticipant(
    participant: string | Participant
  ): Promise<void> {
    await this._participantsEntity.remove(
      typeof participant === "string" ? participant : participant.sid
    );
  }

  /**
   * Send a message to the conversation.
   * @param message Message body for the text message,
   * `FormData` or {@link SendMediaOptions} for media content. Sending FormData
   * is supported only with the browser engine.
   * @param messageAttributes Attributes for the message.
   * @param emailOptions Email options for the message.
   * @return Index of the new message.
   */
  @validateTypesAsync(
    [
      "string",
      FormData,
      literal(null),
      objectSchema("media options", {
        contentType: nonEmptyString,
        media: custom((value) => {
          let isValid =
            (typeof value === "string" && value.length > 0) ||
            value instanceof Uint8Array ||
            value instanceof ArrayBuffer;

          if (typeof Blob === "function") {
            isValid = isValid || value instanceof Blob;
          }

          return [
            isValid,
            "a non-empty string, an instance of Buffer or an instance of Blob",
          ];
        }),
      }),
    ],
    optionalJson,
    [
      "undefined",
      literal(null),
      objectSchema("email attributes", {
        subject: [nonEmptyString, "undefined"],
      }),
    ]
  )
  public async sendMessage(
    message: null | string | FormData | SendMediaOptions,
    messageAttributes?: JSONValue,
    emailOptions?: SendEmailOptions
  ): Promise<number> {
    if (typeof message === "string" || message === null) {
      const response = await this._messagesEntity.send(
        message,
        messageAttributes,
        emailOptions
      );
      return parseToNumber(response.index) ?? 0;
    }

    const response = await this._messagesEntity.sendMedia(
      message,
      messageAttributes,
      emailOptions
    );
    return parseToNumber(response.index) ?? 0;
  }

  /**
   * New interface to prepare for sending a message.
   * Use this instead of {@link Conversation.sendMessage}.
   * @return A MessageBuilder to help set all message sending options.
   */
  public prepareMessage(): MessageBuilder {
    return new MessageBuilder(this.limits, this._messagesEntity);
  }

  /**
   * Set last read message index of the conversation to the index of the last
   * known message.
   * @return Resulting unread messages count in the conversation.
   */
  public async setAllMessagesRead(): Promise<number> {
    await this._subscribeStreams();

    const messagesPage = await this.getMessages(1);

    if (messagesPage.items.length > 0) {
      return this.advanceLastReadMessageIndex(messagesPage.items[0].index);
    }

    return 0;
  }

  /**
   * Set all messages in the conversation unread.
   * @returns New count of unread messages after this update.
   */
  public async setAllMessagesUnread(): Promise<number> {
    await this._subscribeStreams();
    return await this._setLastReadMessageIndex(null);
  }

  /**
   * Set user notification level for this conversation.
   * @param notificationLevel New user notification level.
   */
  @validateTypesAsync(literal("default", "muted"))
  public async setUserNotificationLevel(
    notificationLevel: NotificationLevel
  ): Promise<void> {
    await this._services.commandExecutor.mutateResource<EditNotificationLevelRequest>(
      "post",
      `${this._configuration.links.myConversations}/${this.sid}`,
      {
        notification_level: notificationLevel,
      }
    );
  }

  /**
   * Send a notification to the server indicating that this client is currently
   * typing in this conversation. Typing ended notification is sent after a
   * while automatically, but by calling this method again you ensure that
   * typing ended is not received.
   */
  public typing(): Promise<void> {
    return this._services.typingIndicator.send(this.sid);
  }

  /**
   * Update the attributes of the conversation.
   * @param attributes New attributes.
   */
  @validateTypesAsync(json)
  public async updateAttributes(attributes: JSONValue): Promise<Conversation> {
    await this._services.commandExecutor.mutateResource<
      EditConversationRequest,
      ConversationResponse
    >("post", this._links.self, {
      attributes:
        attributes !== undefined ? JSON.stringify(attributes) : undefined,
    });

    return this;
  }

  /**
   * Update the friendly name of the conversation.
   * @param friendlyName New friendly name.
   */
  @validateTypesAsync("string")
  public async updateFriendlyName(friendlyName: string): Promise<Conversation> {
    if (this._internalState.friendlyName !== friendlyName) {
      await this._services.commandExecutor.mutateResource<
        EditConversationRequest,
        ConversationResponse
      >("post", this._links.self, { friendly_name: friendlyName });
    }

    return this;
  }

  /**
   * Set the last read message index to the current read horizon.
   * @param index Message index to set as last read. If null is provided, then
   * the behavior is identical to {@link Conversation.setAllMessagesUnread}.
   * @returns New count of unread messages after this update.
   */
  @validateTypesAsync([literal(null), nonNegativeInteger])
  public async updateLastReadMessageIndex(
    index: number | null
  ): Promise<number> {
    await this._subscribeStreams();
    return this._setLastReadMessageIndex(index);
  }

  /**
   * Update the unique name of the conversation.
   * @param uniqueName New unique name for the conversation. Setting unique name
   * to null removes it.
   */
  @validateTypesAsync(["string", literal(null)])
  public async updateUniqueName(
    uniqueName: string | null
  ): Promise<Conversation> {
    if (this._internalState.uniqueName !== uniqueName) {
      uniqueName ||= "";

      await this._services.commandExecutor.mutateResource<
        EditConversationRequest,
        ConversationResponse
      >("post", this._links.self, {
        unique_name: uniqueName,
      });
    }

    return this;
  }

  /**
   * Get recipients of all messages in the conversation.
   * @param options Optional configuration, set pageSize to request a specific pagination page size. Page size specifies a number of messages to include in a single batch. Each message may include multiple recipients.
   */
  public async getMessageRecipients(
    options?: PaginatorOptions
  ): Promise<Paginator<RecipientDescriptor>> {
    return await this._services.messageRecipientsClient.getRecipientsFromConversation(
      this.sid,
      options
    );
  }

  /**
   * Load and subscribe to this conversation and do not subscribe to its
   * participants and messages. This or _subscribeStreams will need to be called
   * before any events in the conversation will fire.
   * @internal
   */
  public async _subscribe(): Promise<SyncDocument> {
    if (this._entityPromise) {
      return this._entityPromise;
    }

    this._entityPromise = this._services.syncClient.document({
      id: this._entityName,
      mode: "open_existing",
    });

    try {
      this._entity = await this._entityPromise;
      this._entity.on(SyncDocument.updated, (args) => this._update(args.data));
      this._entity.on(SyncDocument.removed, () =>
        this.emit(Conversation.removed, this)
      );
      this._update(this._entity.data);

      return this._entity;
    } catch (err) {
      this._entity = null;
      this._entityPromise = null;

      if (this._services.syncClient.connectionState != "disconnected") {
        Conversation._logger.error("Failed to get conversation object", err);
      }
      Conversation._logger.debug(
        "ERROR: Failed to get conversation object",
        err
      );

      throw err;
    }
  }

  /**
   * Fetch participants and messages of the conversation. This method needs to
   * be called during conversation initialization to catch broken conversations
   * (broken conversations are conversations that have essential Sync entities
   * missing, i.e. the conversation document, the messages list or the
   * participant map). In case of this conversation being broken, the method
   * will throw an exception that will be caught and handled gracefully.
   * @internal
   */
  public async _fetchStreams() {
    await this._subscribe();
    Conversation._logger.trace(
      "_streamsAvailable, this.entity.data=",
      this._entity?.data
    );

    const data = this._entity?.data as Record<string, string>;
    this._messagesList = await this._services.syncClient.list({
      id: data.messages,
      mode: "open_existing",
    });
    this._participantsMap = await this._services.syncClient.map({
      id: data.roster,
      mode: "open_existing",
    });
  }

  /**
   * Load the attributes of this conversation and instantiate its participants
   * and messages. This or _subscribe will need to be called before any events
   * on the conversation will fire. This will need to be called before any
   * events on participants or messages will fire
   * @internal
   */
  public async _subscribeStreams() {
    try {
      await this._subscribe();
      Conversation._logger.trace(
        "_subscribeStreams, this.entity.data=",
        this._entity?.data
      );

      const data = this._entity?.data as Record<string, string>;
      const messagesObjectName = data.messages;
      const rosterObjectName = data.roster;

      await Promise.all([
        this._messagesEntity.subscribe(
          this._messagesList ?? messagesObjectName
        ),
        this._participantsEntity.subscribe(
          this._participantsMap ?? rosterObjectName
        ),
      ]);
    } catch (err) {
      if (this._services.syncClient.connectionState !== "disconnected") {
        Conversation._logger.error(
          "Failed to subscribe on conversation objects",
          this.sid,
          err
        );
      }
      Conversation._logger.debug(
        "ERROR: Failed to subscribe on conversation objects",
        this.sid,
        err
      );

      throw err;
    }
  }

  /**
   * Stop listening for and firing events on this conversation.
   * @internal
   */
  public async _unsubscribe() {
    if (this._entity) {
      this._entity.close();
      this._entity = null;
      this._entityPromise = null;
    }

    return Promise.all([
      this._participantsEntity.unsubscribe(),
      this._messagesEntity.unsubscribe(),
    ]);
  }

  /**
   * Set conversation status.
   * @internal
   */
  public _setStatus(
    status: ConversationStatus,
    source: ConversationsDataSource
  ) {
    this._dataSource = source;

    if (this._internalState.status === status) {
      return;
    }

    this._internalState.status = status;

    if (status === "joined") {
      this._subscribeStreams().catch((err) => {
        Conversation._logger.debug(
          "ERROR while setting conversation status " + status,
          err
        );
        if (this._services.syncClient.connectionState !== "disconnected") {
          throw err;
        }
      });
      return;
    }

    if (this._entityPromise) {
      this._unsubscribe().catch((err) => {
        Conversation._logger.debug(
          "ERROR while setting conversation status " + status,
          err
        );
        if (this._services.syncClient.connectionState !== "disconnected") {
          throw err;
        }
      });
    }
  }

  /**
   * Update the local conversation object with new values.
   * @internal
   */
  public _update(update) {
    Conversation._logger.trace("_update", update);

    Conversation.preprocessUpdate(update, this.sid);
    const updateReasons = new Set<ConversationUpdateReason>();

    for (const key of Object.keys(update)) {
      const localKey = fieldMappings[key];

      if (!localKey) {
        continue;
      }

      switch (localKey) {
        case fieldMappings.status:
          if (
            !update.status ||
            update.status === "unknown" ||
            this._internalState.status === update.status
          ) {
            break;
          }

          this._internalState.status = update.status;
          updateReasons.add(localKey);

          break;
        case fieldMappings.attributes:
          if (isEqual(this._internalState.attributes, update.attributes)) {
            break;
          }

          this._internalState.attributes = update.attributes;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastConsumedMessageIndex:
          if (
            update.lastConsumedMessageIndex === undefined ||
            update.lastConsumedMessageIndex ===
              this._internalState.lastReadMessageIndex
          ) {
            break;
          }

          this._internalState.lastReadMessageIndex =
            update.lastConsumedMessageIndex;
          updateReasons.add("lastReadMessageIndex");

          break;
        case fieldMappings.lastMessage:
          if (this._internalState.lastMessage && !update.lastMessage) {
            delete this._internalState.lastMessage;
            updateReasons.add(localKey);

            break;
          }

          this._internalState.lastMessage =
            this._internalState.lastMessage || {};

          if (
            update.lastMessage?.index !== undefined &&
            update.lastMessage.index !== this._internalState.lastMessage.index
          ) {
            this._internalState.lastMessage.index = update.lastMessage.index;
            updateReasons.add(localKey);
          }

          if (
            update.lastMessage?.timestamp !== undefined &&
            this._internalState.lastMessage?.dateCreated?.getTime() !==
              update.lastMessage.timestamp.getTime()
          ) {
            this._internalState.lastMessage.dateCreated =
              update.lastMessage.timestamp;
            updateReasons.add(localKey);
          }

          if (isEqual(this._internalState.lastMessage, {})) {
            delete this._internalState.lastMessage;
          }

          break;
        case fieldMappings.state:
          const state = update.state || undefined;

          if (state !== undefined) {
            state.dateUpdated = new Date(state.dateUpdated);
          }

          if (isEqual(this._internalState.state, state)) {
            break;
          }

          this._internalState.state = state;
          updateReasons.add(localKey);

          break;
        case fieldMappings.bindings:
          if (isEqual(this._internalState.bindings, update.bindings)) {
            break;
          }

          this._internalState.bindings = update.bindings;
          updateReasons.add(localKey);

          break;
        default:
          const isDate = update[key] instanceof Date;
          const keysMatchAsDates =
            isDate &&
            this._internalState[localKey]?.getTime() === update[key].getTime();
          const keysMatchAsNonDates = !isDate && this[localKey] === update[key];

          if (keysMatchAsDates || keysMatchAsNonDates) {
            break;
          }

          this._internalState[localKey] = update[key];
          updateReasons.add(localKey);
      }
    }

    if (updateReasons.size > 0) {
      this.emit(Conversation.updated, {
        conversation: this,
        updateReasons: [...updateReasons],
      });
    }
  }

  /**
   * Handle onMessageAdded event.
   */
  private _onMessageAdded(message) {
    for (const participant of this._participants.values()) {
      if (participant.identity === message.author) {
        participant._endTyping();
        break;
      }
    }
    this.emit(Conversation.messageAdded, message);
  }

  /**
   * Set last read message index.
   * @param index New index to set.
   */
  private async _setLastReadMessageIndex(
    index: number | null
  ): Promise<number> {
    const result = await this._services.commandExecutor.mutateResource<
      EditLastReadMessageIndexRequest,
      EditLastReadMessageIndexResponse
    >("post", `${this._configuration.links.myConversations}/${this.sid}`, {
      last_read_message_index: index,
    });

    return result.unread_messages_count;
  }
}

export {
  ConversationDescriptor,
  Conversation,
  ConversationServices,
  ConversationUpdateReason,
  ConversationStatus,
  NotificationLevel,
  ConversationState,
  ConversationUpdatedEventArgs,
  SendMediaOptions,
  SendEmailOptions,
  LastMessage,
  ConversationBindings,
  ConversationEmailBinding,
};
