/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import moment from "moment-timezone";
import "moment/locale/sv";

import { attachRootComponentToElement } from "../common/react/bootstrap";
import Spot from "../booking_editor/models/spot";
import Pier from "../booking_editor/models/pier"
import SpotMap from "./containers/spot_map_container";
import isGoogleMapsLoaded from "../common/utils/is_google_maps_loaded";
import reducer from "./reducers/reducer"


export var spotMap = (element) =>
  attachRootComponentToElement(
    element,
    SpotMap,
    initInitialState,
    reducer
  )

export var initInitialState = function(props) {
  let {lat, lng, zoom, length, width, depth, locale, assets } = props;
  const availability = JSON.parse(props.availability);
  const isCompatible = availability.is_compatible;
  const mapType = props.map_type;
  const dockId = props.dock_id;
  const dockParam = props.dock_param;
  const isAdmin = props.is_admin;
  const isPaynstay = props.is_paynstay;
  const showPayLinkHint = props.show_paynstay_hint
  const hasBooking = props.has_booking;
  const spots = Spot.deserialize(JSON.parse(props.spots));
  const piers = Pier.deserialize(JSON.parse(props.piers), dockId);
  const translations = JSON.parse(props.translations);
  if (length) { length = parseFloat(length); }
  if (width) { width = parseFloat(width); }
  if (depth) { depth = parseFloat(depth); }
  const timeZone = props.time_zone;
  const isCamping = props.camping;
  const googleMapsLoaded = isGoogleMapsLoaded()
  const from = moment.tz(props.from, timeZone).clone()
  const to = moment.tz(props.to, timeZone).clone()

  moment.locale(locale)

  return Object.assign(
    {},
    {
      lat,
      lng,
      zoom,
      mapType,
      dockId,
      dockParam,
      length,
      width,
      depth,
      isCompatible,
      spots,
      piers,
      translations,
      locale,
      isAdmin,
      isPaynstay,
      showPayLinkHint,
      hasBooking,
      assets,
      timeZone,
      isCamping,
      googleMapsLoaded,
      from,
      to,
    }
  )
};
